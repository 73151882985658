import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const useLinks = () => {
  const data = useStaticQuery(graphql`
  {
    wordpressAcfOptions {
      options {
        login
        ride_receipts
        reserve_now
        google_play
        app_store
        toll_free
        local
        facebook
        twitter
        instagram
      }
    }
  }
  `)
  return data.wordpressAcfOptions.options
}
export default useLinks

export const ReserveNow = ({ style, text = "Reserve Now" }) => {
  const { reserve_now } = useLinks()
  return (
    <a
      href={reserve_now}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 500,
        background: "var(--green)",
        padding: "0.5rem 1.5rem",
        textAlign: "center",
        ...style,
      }}
    >
      {text}
    </a>
  )
}

export const LearnMore = ({ className, text = "Learn More" }) => {
  return (
    <Link to="/why-king" className={className}>
      {text}
    </Link>
  )
}
