import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import rideReceipts from "../../images/header/receipt.svg"

const Navbar = styled.nav`
  background: var(--white);
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 1rem 0;
  padding: 1rem 0;
  overflow-y: scroll;
  height: 100%;
  .primary-link {
    position: relative;
    padding: 1rem;
    margin: 1.5rem 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &.active {
      & > a {
        color: var(--green);
      }
    }
    a {
      font-size: 1rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      color: var(--black);
    }
    .plus {
      font-family: "Montserrat", sans-serif;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      border-radius: 50%;
      background: var(--black);
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--white);
    }
  }
`

const Container = styled.div`
  .secondary-link {
    padding: 0.5rem;
    margin: 0.5rem 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-family: "Montserrat", sans-serif;
    .parent {
      display: block;
      font-size: 0.9rem;
      color: var(--black);
      position: relative;
      .plus {
        font-family: "Montserrat", sans-serif;
        position: absolute;
        right: 20%;
        font-size: 1rem;
      }
    }
    .children {
      display: none;
      &.active {
        display: block;
      }
      .child {
        display: block;
        font-size: 0.8rem;
        color: var(--grey);
        margin: 1rem 0;
      }
    }
  }
`

const ExternalLinks = styled.div`
  .ride-receipts {
    color: var(--black);
    margin: 4rem 0 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 0.8rem;
    img {
      filter: var(--black-filter);
      width: 20px;
      margin-right: 0.5rem;
    }
  }
  .quotes {
    width: 100%;
  }
`

const DropDown = ({ item }) => {
  const [isDropdownActive, setDropdownActive] = useState(null)

  function handleClick(e) {
    const currIndex = e.currentTarget.dataset.index
    isDropdownActive === currIndex
      ? setDropdownActive(null)
      : setDropdownActive(currIndex)
  }

  return (
    <Container>
      {item.childItems.nodes.map((sub_item, i) => (
        <div className="secondary-link" key={i}>
          {sub_item.childItems.nodes.length !==0 ? (
            <span className="parent" data-index={i} onClick={handleClick}>
              {sub_item.label}
              <span className="plus">
                {parseInt(isDropdownActive) === i ? "-" : "+"}
              </span>
            </span>
          ) : (
            <Link className="parent" to={sub_item.path}>
              {sub_item.label}
            </Link>
          )}
          <div
            className={`children ${
              parseInt(isDropdownActive) === i ? "active" : null
            }`}
          >
            {sub_item.childItems.nodes.length !==0 &&
              sub_item.childItems.nodes.map((sub_sub_item, j) => (
                <Link
                  className="child"
                  to={sub_sub_item.path}
                  key={j}
                >
                  {sub_sub_item.label}
                </Link>
              ))}
          </div>
        </div>
      ))}
    </Container>
  )
}

const HeaderLinks = ({ extLinks, setModalActive }) => {
  const [isDropdownActive, setDropdownActive] = useState(null)

  function handleClick(e) {
    const currIndex = e.currentTarget.dataset.index
    isDropdownActive === currIndex
      ? setDropdownActive(null)
      : setDropdownActive(currIndex)
  }

  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        menuItems(where: {location: PRIMARY, parentDatabaseId: 0}) {
          nodes {
            label
            path
            childItems {
              nodes {
                label
                path
                childItems {
                  nodes {
                    label
                    path
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Navbar>
      {data.wpgraphql.menuItems.nodes.map((item, index) => (
        <div key={index}>
          <div
            className={`primary-link ${
              parseInt(isDropdownActive) === index ? "active" : ""
            }`}
          >
            <Link to={item.path}>{item.label}</Link>

            {item.childItems.nodes.length !==0 && (
              <span className="plus" data-index={index} onClick={handleClick}>
                {parseInt(isDropdownActive) === index ? "-" : "+"}
              </span>
            )}
          </div>
          {item.childItems.nodes.length !==0 && parseInt(isDropdownActive) === index && (
            <DropDown item={item} />
          )}
        </div>
      ))}

      <ExternalLinks>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={extLinks.ride_receipts}
          className="ride-receipts"
        >
          <img src={rideReceipts} alt="ride receipts icon" />
          <span>Ride Receipts</span>
        </a>
        <button className="quotes" onClick={() => setModalActive(true)}>
          Quotes & Reservations
        </button>
      </ExternalLinks>
    </Navbar>
  )
}

export default HeaderLinks
