import React from "react"
import styled from "styled-components"
import ReactDOM from "react-dom"
import closeButton from "../images/fleet/close.svg"
import device from "./device"

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Card = styled.div`
  width: 45vmax;
  height: 45vmax;
  min-height: 300px;
  max-width: 1000px;
  max-height: 1000px;
  background: transparent;
  position: relative;
  ${device.small`width: var(--spread);`}
  .close-button {
    cursor: pointer;
    position: absolute;
    width: 40px;
    right: 0;
    top: 0;
    z-index: 2;
    transform: translate(50%, -50%);
    ${device.small`width: 30px;`}
  }
`

const CardSmall = styled.div`
  width: 650px;
  ${device.medium`width: 500px;`}
  ${device.small`width: var(--spread);`}
  background: transparent;
  position: relative;
  .close-button {
    cursor: pointer;
    position: absolute;
    width: 40px;
    right: 0;
    top: 0;
    z-index: 2;
    transform: translate(50%, -50%);
    ${device.small`width: 30px;`}
  }
`

export default function Modal({ setModalActive, isSmall, children }) {
  return ReactDOM.createPortal(
    <Overlay>
      {isSmall ? (
        <CardSmall>
          {children}
          <img
            className="close-button"
            src={closeButton}
            alt="Close gallery"
            onClick={() => setModalActive(false)}
          />
        </CardSmall>
      ) : (
        <Card>
          {children}
          <img
            className="close-button"
            src={closeButton}
            alt="Close gallery"
            onClick={() => setModalActive(null)}
          />
        </Card>
      )}
    </Overlay>,
    document.body
  )
}
