import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useTransition, animated } from "react-spring"
import phone from "../../images/header/phone.svg"

import useLinks from "../hooks/useLinks"
import Modal from "../modal"
import Popup from "./popup"
import HeaderLinks from "./header-links-mobile"

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  background: var(--green);
  .button-wrapper {
    position: relative;
    padding: 1rem 1.5rem;
    background: var(--black);
    align-self: stretch;
    display: flex;
    align-items: center;
    .placeholder {
      width: 30px;
      background: transparent;
    }
  }
  .quotes {
    flex: 1;
    position: relative;
    z-index: 1000;
    height: 54.38px;
    background: var(--green);
    border: 0;
    display: block;
    text-align: center;
    letter-spacing: 2px;
    color: var(--white);
    font-size: 0.8rem;
  }
  .phone-wrapper {
    position: relative;
    z-index: 1000;
    padding: 0.5rem 1rem;
    background: #b0b17d;
    .phone {
      display: block;
      background: var(--white);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 55%;
      }
    }
  }
`

const Hamburger = styled.div`
  position: ${props => (props.active ? "fixed" : "absolute")};
  width: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  z-index: 1000;
 
    top: ${props => (props.active ? "10px" : "auto")};
    right: ${props => (props.active ? "10px" : "auto")};
 
  .bar {
    border-radius: 50px;
    width: 100%;
    height: 3px;
    background: white;
    transition: all 0.3s;
    &:first-child {
      opacity: ${props => (props.active ? 0 : 1)};
    }
    &:last-child {
      opacity: ${props => (props.active ? 0 : 1)};
    }
    &.mid {
      top: 50%;
      position: absolute;
    }
    &.left {
      transform: ${props =>
        props.active
          ? "rotate(45deg) translate(-1px,-50%)"
          : "translateY(-50%)"};
    }
    &.right {
      transform: ${props =>
        props.active
          ? "rotate(-45deg) translate(1px,-50%)"
          : "translateY(-50%)"};
    }
  }
`

const Menu = styled(animated.div)`
  padding-top: 1.5rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--black);
  z-index: 1001;
`

const HeaderMobile = () => {
  const extLinks = useLinks()
  const [active, setActive] = useState(false)
  const [isModalActive, setModalActive] = useState(false)

  const transitions = useTransition(active, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden"
      document.body.style.position = "fixed"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.position = "static"
    }
  }, [active])

  function toggleActive() {
    setActive(!active)
  }

  return (
    <>
    <Wrapper>
      <div className="button-wrapper">
        <div className="placeholder"></div>
        <Hamburger className="openMenu" onClick={toggleActive} active={active}>
          <div className="bar"></div>
          <div className="bar mid left"></div>
          <div className="bar mid right"></div>
          <div className="bar"></div>
        </Hamburger>
      </div>
      <button className="quotes" onClick={() => setModalActive(true)}>
        Quotes & Reservations
      </button>
      
      <div className="phone-wrapper">
        <a href={`tel:${extLinks.local}`} className="phone">
          <img src={phone} alt="Phone" />
        </a>
      </div>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Menu key={key} style={props}>
              <Hamburger onClick={toggleActive} active={active}>
          <div className="bar"></div>
          <div className="bar mid left"></div>
          <div className="bar mid right"></div>
          <div className="bar"></div>
        </Hamburger>
              <HeaderLinks
                extLinks={extLinks}
                setModalActive={setModalActive}
              />
            </Menu>
          )
      )}
      
    </Wrapper>
    
    {isModalActive && (
        <Modal isSmall={true} setModalActive={setModalActive}>
          <Popup extLinks={extLinks} />
        </Modal>
      )}
    </>
  )
}

export default HeaderMobile
