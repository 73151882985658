import { useEffect, useState } from "react"

export default function useViewport() {
  const [viewport, setViewport] = useState(null)

  useEffect(() => {
    const handler = () => setViewport(window.innerWidth)
    handler()
    window.addEventListener("resize", handler)
    return () => window.removeEventListener("resize", handler)
  }, [])
  return viewport
}
