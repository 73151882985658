import React, { useState } from "react"
import styled from "styled-components"
import device from "../device"
import useLinks from "../hooks/useLinks"

import phone from "../../images/header/phone.svg"
import rideReceipts from "../../images/header/receipt.svg"
import login from "../../images/header/login.svg"

import Modal from "../modal"
import Popup from "./popup"


const OuterWrapper = styled.div`
  background: var(--darkgrey);
  color: var(--white);
`

const Wrapper = styled.header`
  width: 95%;
  max-width: var(--limit);
  margin-left: auto;
  display: flex;
  ${device.small`display: none`}
  ${device.large`margin-right: auto;`}
`

const Contact = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  .local {
    border-right: 2px solid var(--white);
    padding-right: 2rem;
    display: flex;
    align-items: center;
    .image-wrapper {
      margin-right: 1rem;
      border: 1px solid var(--green);
      border-radius: 50%;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 55%;
      }
    }
    span {
      opacity: 0;
    }
  }
  .toll-free {
    padding-left: 2rem;
  }
  .number-wrapper {
    span {
      display: block;
      font-size: 0.7rem;
      letter-spacing: 1px;
    }
    a {
      display: block;
      color: var(--green);
      font-size: 1rem;
      letter-spacing: 2px;
    }
  }
`

const ExternalLinks = styled.div`
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    margin-right: 3rem;
    letter-spacing: 1px;
  }
  .quotes {
    cursor: pointer;
    font-size: 0.8rem;
    border: 0;
    margin: 0;
    padding: 1.5rem 2rem;
    display: block;
    background: var(--green);
    letter-spacing: 2px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  img {
    margin-right: 0.5rem;
    &.receipt-icon {
      width: 20px;
    }
    &.login-icon {
      width: 20px;
    }
  }
`

const Header = () => {
  const [isModalActive, setModalActive] = useState(false)

  const extLinks = useLinks()
  return (
    <>
    <OuterWrapper>
      <Wrapper>
        <Contact>
          <div className="local">
            <div className="image-wrapper">
              <img src={phone} alt="toll free phone number" />
            </div>
            <div className="number-wrapper">
              <a href={`tel:${extLinks.local}`}>{extLinks.local}</a>
            </div>
          </div>
          {extLinks.toll_free &&
          <div className="toll-free">
            <div className="number-wrapper">
              <span>Toll-Free:</span>
              <a href={`tel:${extLinks.toll_free}`}>{extLinks.toll_free}</a>
            </div>
          </div>
}
        </Contact>

        <ExternalLinks>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={extLinks.ride_receipts}
          >
            <img
              src={rideReceipts}
              alt="ride receipts icon"
              className="receipt-icon"
            />
            <span>Ride Receipts</span>
          </a>
          <a target="_blank" rel="noopener noreferrer" href={extLinks.login}>
            <img src={login} alt="login icon" className="login-icon" />
            <span>Login</span>
          </a>
          <button className="quotes" onClick={() => setModalActive(true)}>
            Quotes & Reservations
          </button>
          {isModalActive && (
            <Modal isSmall={true} setModalActive={setModalActive}>
              <Popup extLinks={extLinks} />
            </Modal>
          )}
        </ExternalLinks>
      </Wrapper>
    </OuterWrapper>
    </>
  )
}

export default Header
