import React from "react"
import useViewport from "./hooks/useViewport"
import { sizes } from "./device"
import "./layout.css"
import Footer from "./footer"

import Header from "./header/header"
import HeaderMobile from "./header/header-mobile"

const Layout = ({ children }) => {
  const vw = useViewport()
  const isTablet = vw <= sizes.medium
  return (
    <>
      {vw && (isTablet ? <HeaderMobile /> : <Header />)}
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
