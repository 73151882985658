import React from "react"
import styled from "styled-components"
import device from "../device"

const handleClick = buttonName => {
  if (typeof window !== "undefined") {
    window.gtag("event", "click", {
      event_category: "button",
      event_label: buttonName,
    })
  }
}

const Container = styled.div`
  color: var(--black);
  background: var(--white);
  padding: 0.5rem;
  text-align: center;
  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    padding: 2rem;
    color: var(--white);
    background: var(--green);
  }
  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 1rem;
    margin: 3rem 0;
  }
  .links {
    margin: 2rem 0;
    display: flex;
    align-items: center;
    ${device.small`display: block;`}
    a {
      display: block;
      font-size: 0.9rem;
      flex: 1;
      margin: 0 2rem;
      padding: 1rem 2rem;
      border-radius: 5px;
      background: var(--green);
      color: var(--white);
      ${device.medium`margin: 0 1rem;`}
      ${device.small`margin: 1.5rem;`}
      :last-child {
        background: var(--darkgrey);
      }
    }
  }
`

const Popup = ({ extLinks }) => (
  <Container>
    <h2>How can we serve you?</h2>
    <div className="links">
      <a
        href="/get-a-price-quote/"
        onClick={() => handleClick("Price Quote link")}
      >
        Get a Quote
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={extLinks.reserve_now}
        onClick={() => handleClick("Reservation link")}
      >
        Make a Reservation
      </a>
    </div>
    <h3>Thank you for choosing king transportation</h3>
  </Container>
)

export default Popup
